<!-- SobreNosotros.vue -->
<template>
  <div class="container mx-auto mt-8 p-4">
    <h1 class="text-2xl font-bold mb-4">Sobre Nosotros</h1>
    <p class="text-gray-600">
      Conoce más sobre nuestra empresa y nuestro equipo.
    </p>
  </div>
</template>

<script>
export default {
  name: "SobreNosotros",
};
</script>
